/** @jsxImportSource @emotion/react */
import React, { forwardRef, useRef, useState } from "react";
import {
	selectPeerByID,
	useHMSActions,
	useHMSStore,
} from "@100mslive/react-sdk";
import {
	CheckIcon,
	ChevronDownIcon,
	ChevronUpIcon,
} from "@100mslive/react-icons";
import {
	Box,
	Checkbox,
	Text,
	Tooltip,
} from "@100mslive/react-ui";
import {Button, Flex, Label, Dropdown} from "../mkui";
import {textEllipsis} from "../styles/styles";
import clsx from "clsx";
import {Dialog} from "./Dialogs/Dialogs";
import { useFilteredRoles } from "../lib/hooks";

const PeerName = forwardRef(({ children, maxWidth, ...rest }, ref) => (
	<div
		{...rest}
		ref={ref}
		as="strong"
		variant="body2"
		className={clsx("inline-block text-inherit text-lg", textEllipsis(maxWidth))}
		>
		{children}
	</div>
));

export const RoleChangeModal = ({ peerId, onOpenChange }) => {
	const peer = useHMSStore(selectPeerByID(peerId));
	const roles = useFilteredRoles();
	const [selectedRole, setRole] = useState(peer?.roleName);
	const [requestPermission, setRequestPermission] = useState(true);
	const hmsActions = useHMSActions();
	const [open, setOpen] = useState(false);
	const [peerNameRef, setPeerNameRef] = useState();
	const ref = useRef();
	if (!peer) {
		return null;
	}
	const peerNameMaxWidth = 200;

	return (
		<Dialog.Root defaultOpen onOpenChange={onOpenChange}>
			<Dialog.Portal>
				<Dialog.Overlay />
				<Dialog.Content className="p-4 w-['min(400px,80%)]">
					<Dialog.Title className="p-0" asChild>
						<h5>
							Rolle wechseln
						</h5>
					</Dialog.Title>
					<Dialog.Description asChild>
						<div className="flex gap-x-2 flex-wrap mt-2 mb-4 text-app-textNeg">
							Wechsle die Rolle von
							{peerNameRef && peerNameRef.clientWidth === peerNameMaxWidth ? (
								<Tooltip title={peer.name} side="top">
									<PeerName ref={setPeerNameRef} maxWidth={peerNameMaxWidth}>
										{peer.name}
									</PeerName>
								</Tooltip>
							) : (
								<PeerName ref={setPeerNameRef} maxWidth={peerNameMaxWidth}>
									{peer.name}
								</PeerName>
							)}
							zu
						</div>
					</Dialog.Description>
					<Flex align="center" className="w-full mb-5">
						<div className="relative flex-1 min-w-0">
							<Dropdown.Root
								open={open}
								onOpenChange={setOpen}
								className="w-full"
							>
								<Dropdown.Trigger
									data-testid="open_role_selection_dropdown"
									asChild
									className="border-dropdown bg-app-surfaceHilite rounded-dropdown py-3 px-4"
									ref={ref}
								>
									<Flex
										align="center"
										justify="between"
										className="w-full"
									>
										<span>{selectedRole}</span>
										{open ? <ChevronUpIcon /> : <ChevronDownIcon />}
									</Flex>
								</Dropdown.Trigger>
								<Dropdown.Portal>
									<Dropdown.Content
										align="start"
										sideOffset={8}
										className={clsx("z-1000", ref.current && `w-[${ref.current.clientWidth}px]`)}
									>
										{roles.map(role => {
											return (
												<Dropdown.Item
													data-testid={role}
													key={role}
													onSelect={() => setRole(role)}
													className={clsx("px-4", role === selectedRole ? 'bg-app-surfaceHilite' : null)}
												>
													{role}
												</Dropdown.Item>
											);
										})}
									</Dropdown.Content>
								</Dropdown.Portal>
							</Dropdown.Root>
						</div>
					</Flex>
					{!peer.isLocal && (
						<Flex justify="between" css={{ w: "100%", mb: "$10" }}>
							<Label
								htmlFor="requestRoleChangePermission"
								className="cursor-pointer"
							>
								Erlaubnis anfragen
							</Label>
							<Checkbox.Root
								checked={requestPermission}
								onCheckedChange={value => setRequestPermission(value)}
								id="requestRoleChangePermission"
								data-testid="force_role_change_checkbox"
							>
								<Checkbox.Indicator>
									<CheckIcon width={16} height={16} />
								</Checkbox.Indicator>
							</Checkbox.Root>
						</Flex>
					)}
					<Flex
						justify="center"
						align="center"
						gap="4"
						className="w-full"
					>
						<div className="w-1/2">
							<Dialog.Close className="w-full" asChild>
								<Button
									variant="standard"
									outlined
									className="w-full"
									data-testid="cancel_button"
								>
									Abbrechen
								</Button>
							</Dialog.Close>
						</div>
						<div className="w-1/2">
							<Button
								data-testid="change_button"
								variant="primary"
								className="w-full"
								onClick={async () => {
									await hmsActions.changeRoleOfPeer(
										peerId,
										selectedRole,
										peer.isLocal ? true : !requestPermission
									);
									onOpenChange(false);
								}}
							>
								Wechseln
							</Button>
						</div>
					</Flex>
				</Dialog.Content>
			</Dialog.Portal>
		</Dialog.Root>
	);
};
