import {styled, theme} from 'twin.macro';

export const Root = styled.div({
	//padding: '0.75rem',
	// show videotile context menu on hover
	// [`&:hover .tile-menu`]: {
	//   display: 'inline-block',
	// },
});

interface ContainerProps {
	transparent?: boolean;
}
type ContainerPropTypes = JSX.IntrinsicElements["div"] & ContainerProps;

const Container = styled.div((props:ContainerPropTypes) => [{
	width: '100%',
	height: '100%',
	position: 'relative',
	borderRadius: theme('components.videotile.borderRadius'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	},
	props.transparent ? {background: theme('components.videotile.background')} : {background: 'transparent'}
]);

const Overlay = styled.div({
	position: 'absolute',
	width: '100%',
	height: '100%',
});

const Info = styled.div({
	color: theme('components.videotile.color'),
	position: 'absolute',
	bottom: '5px',
	left: '50%',
	fontSize: theme('fontSize.app.small'),
	transform: 'translateX(-50%)',
	textAlign: 'center',
	width: '80%',
	overflow: 'hidden',
	whiteSpace: 'nowrap',
	textOverflow: 'ellipsis',
	fontFamily: theme('components.videotile.fontFamily'),
});

const AttributeBox = styled.div({
	position: 'absolute',
	color: theme('components.videotile.color'),
});

const AudioIndicator = styled.div({
	position: 'absolute',
	top: theme('spacing.2'),
	right: theme('spacing.2'),
	color: theme('components.videotile.indicatorcolor'),
	background: theme('components.videotile.indicatorbackground'),
	borderRadius: '50%',
	width: theme('spacing.6'),
	height: theme('spacing.6'),
	marginBottom: '5px',
	display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const FullScreenButton = styled.button({
	width: theme('spacing.9'),
	height: theme('spacing.9'),
	color: theme('components.videotile.button.color'),
	borderRadius: theme('components.videotile.button.borderRadius'),
	backgroundColor: theme('components.videotile.button.background'),
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	position: 'absolute',
	top: theme('spacing.2'),
	right: theme('spacing.2'),
	zIndex: 5,
	'&:not([disabled]):focus': {
		outline: 'none',
		boxShadow: theme('components.videotile.button.shadow'),
	},
});

const AvatarContainer = styled.div({
	display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
	flexDirection: 'column',
	position: 'absolute',
	left: '50%',
	top: '50%',
	transform: 'translateX(-50%) translateY(-50%)',
	width: '100%',
	height: '100%',
	'& > * + *': {
		marginBottom: '0',
		marginTop: theme('spacing.2'),
	},
});

interface VideoTileType {
	Root: typeof Root;
	Container: typeof Container;
	Overlay: typeof Overlay;
	Info: typeof Info;
	AudioIndicator: typeof AudioIndicator;
	AvatarContainer: typeof AvatarContainer;
	AttributeBox: typeof AttributeBox;
	FullScreenButton: typeof FullScreenButton;
}

export const StyledVideoTile: VideoTileType = {
	Root,
	Container,
	Overlay,
	Info,
	AudioIndicator,
	AvatarContainer,
	AttributeBox,
	FullScreenButton,
};
