import { Root, Trigger, Sub, SubTrigger, SubContent, Content, Item, Separator, Label, Group, Portal, CheckboxItem, ItemIndicator } from '@radix-ui/react-dropdown-menu';
import tw from 'twin.macro';

const DropdownRoot = tw(Root)``;

const DropdownTrigger = tw(Trigger)`
	cursor-pointer appearance-none
	dataOpen:bg-app-dropdown-bg
	focus:outline-none
	focus-visible:shadow-sm
`;

const DropdownTriggerItem = tw(SubTrigger)`
	w-full text-app-dropdown-text p-4
	flex items-center
	hover:cursor-pointer hover:bg-app-surfaceHilite
	focus-visible:bg-app-surfaceHilite
`;

const DropdownItem = tw(Item)`
	text-app-dropdown-text p-4
	flex items-center outline-none
	hover:cursor-pointer hover:bg-app-surfaceHilite
	focus-visible:bg-app-surfaceHilite
`;

const DropdownItemSeparator = tw(Separator)`
	h-px bg-app-neutral-300 my-2 mx-4
`;

const DropdownContent = tw(Content)`
	w-[calc(100%+30px)] max-h-[60vh] py-2 rounded-none
	bg-app-dropdown-bg overflow-y-auto
	z-20 shadow-dropdown
`;

const DropdownLabel = tw(Label)`
	flex items-center h-6 w-full py-4 px-2
`;


const DropdownGroup = tw(Group)``;

const DropdownSubMenu = tw(Sub)``;

const DropdownSubMenuContent = tw(SubContent)`
	w-[calc(100%+30px)] max-h-64 rounded-dropdown
	bg-app-dropdown-bg overflow-y-auto
	shadow-dropdown z-20 text-app-dropdown-text
`;

const DropdownCheckboxItem = tw(CheckboxItem)`
	text-app-dropdown-text p-4
	flex items-center outline-none
	hover:cursor-pointer hover:bg-app-surfaceHilite
	focus-visible:bg-app-surfaceHilite
	gap-1
`;


const DropdownItemIndicator = tw(ItemIndicator)`
	w-4 inline-flex items-center justify-center
`;

export const Dropdown = {
	Root: DropdownRoot,
	Trigger: DropdownTrigger,
	TriggerItem: DropdownTriggerItem,
	Content: DropdownContent,
	Portal: Portal, 
	SubMenu: DropdownSubMenu,
	SubMenuContent: DropdownSubMenuContent,
	Item: DropdownItem,
	Label: DropdownLabel,
	Group: DropdownGroup,
	ItemSeparator: DropdownItemSeparator,
	CheckboxItem: DropdownCheckboxItem,
	ItemIndicator: DropdownItemIndicator,
};
