import {
	selectDominantSpeaker,
	selectIsConnectedToRoom,
	useHMSStore,
} from "@100mslive/react-sdk";
import { VolumeOneIcon } from "@100mslive/react-icons";
import { Flex } from "../../mkui";
import { useLogo } from "../AppData/useUISettings";
import { isStreamingKit } from "../../lib/utils";

export const SpeakerTag = () => {
	const dominantSpeaker = useHMSStore(selectDominantSpeaker);
	return dominantSpeaker && dominantSpeaker.name ? (
		<Flex
			align="center"
			justify="center"
			className="flex-1 text-app-text hidden md:inline-block"
		>
			<VolumeOneIcon />
			<span className="text-md ml-4 text-ellipsis max-w-[180px]">
				{dominantSpeaker.name}
			</span>
		</Flex>
	) : (
		<></>
	);
};


export const Logo = () => {
	const logo = useLogo();
	const isConnected = useHMSStore(selectIsConnectedToRoom);
	// Hide logo for now as there is not enough space
	if (isConnected && isStreamingKit()) {
		return null;
	}

	const LogoImg = <img
		className="p-1 w-auto max-h-6 md:max-h-8"
		src={logo}
		alt="Brand Logo"
		width={132}
		height={40}
	/>

	if (isConnected) {
		return <>{LogoImg}</>;
	} else {
		return (
			<a href="/" className="hidden sm:block">
				{LogoImg}
			</a>
		)
	};
};
