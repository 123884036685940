import React from "react";
import { useAutoplayError } from "@100mslive/react-sdk";
import { Button } from "../../mkui";
import { DialogContent, DialogRow, Dialog} from "../Dialogs/Dialogs";

export function AutoplayBlockedModal() {
	const { error, resetError, unblockAudio } = useAutoplayError();
	return (
		<Dialog.Root
			open={!!error}
			onOpenChange={value => {
				if (!value) {
					unblockAudio();
				}
				resetError();
			}}
		>
			<DialogContent title="Erlaubnis benötigt" closeable={false}>
				<DialogRow>
					<p className="text-md">
						Der Brwoser benötigt die Erlaubnis den Ton abspielen zu dürfen. Bitte gebe diese Erlaubnis.
					</p>
				</DialogRow>
				<DialogRow justify="end">
					<Button
						color="primary"
						onClick={() => {
							unblockAudio();
							resetError();
						}}
					>
						Ton erlauben
					</Button>
				</DialogRow>
			</DialogContent>
		</Dialog.Root>
	);
}
