import React, { forwardRef } from 'react';
import clsx from 'clsx';

type ButtonColors = "primary" | "secondary" | "tertiary" | "warning" | "neutral"
type ButtonSizes = "regular" | "large" | "small" | "xs"

const buttonClasses = {
	base: clsx('btn-icon')
}

interface IconButtonProps {
	active?: boolean;
	size?: ButtonSizes;
	color?: ButtonColors;
	square?: boolean;
	text?: string;
	aslink?: boolean;
	outline?: boolean;
	nofill?: boolean;
	noshadow?: boolean;
	// selected?: boolean;
};

type PropTypes = JSX.IntrinsicElements["button"] & JSX.IntrinsicElements["a"] & IconButtonProps;

export const IconButton = forwardRef<HTMLButtonElement & HTMLAnchorElement, PropTypes>(
	({
		children,
		active,
		size,
		color,
		square = false,
		text,
		aslink = false,
		outline,
		nofill,
		noshadow,
		// selected,
		className,
		...rest
	}, ref) => {
		if (aslink) {
			return (
				<a
					ref={ref}
					className={
						clsx(
							buttonClasses.base,
							size && `${size}`,
							!color && 'neutral', // neutral color is the default
							color && `${color}`,
							square && 'square',
							text && 'with-text',
							outline && 'btnoutline',
							nofill && 'nofill',
							active && 'active',
							noshadow && 'no-shadow',
							className
						)
					}
					{...rest}
				>
					<>
						<div className="flex justify-center items-center flex-grow w-full">{children}</div>
						{text && <span>{text}</span>}
					</>
				</a>
			)
		} else {
			return (
				<button
					ref={ref}
					type="button"
					className={
						clsx(
							buttonClasses.base,
							size && `${size}`,
							!color && 'neutral', // neutral color is the default
							color && `${color}`,
							square && 'square',
							text && 'with-text',
							outline && 'btnoutline',
							nofill && 'nofill',
							active && 'active',
							noshadow && 'no-shadow',
							className
						)
					}
					{...rest}
				>
					<>
						<div className="flex justify-center items-center flex-grow w-full">{children}</div>
						{text && <span>{text}</span>}
					</>
				</button>
			)
		}
	}
)

IconButton.displayName = "IconButton";
