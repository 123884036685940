/** @jsxImportSource @emotion/react */
import { Fragment, useState } from "react";
// import { useParams } from "react-router-dom";
import {
	selectIsConnectedToRoom,
	selectPermissions,
	useHMSActions,
	useHMSStore,
	selectLocalPeerRoleName,
} from "@100mslive/react-sdk";
// import tw from "twin.macro";
import {
	AlertTriangleIcon,
	ExitIcon,
	HangUpIcon,
	VerticalMenuIcon,
} from "../styles/AppIcons";
import {
	Box,
	Button,
	Dropdown,
	Flex,
	IconButton,
	Tooltip,
} from "../mkui";
import {
	Dialog,
	DialogCheckbox,
	DialogContent,
	DialogRow,
} from "../components/Dialogs/Dialogs";
import { useNavigation } from "../components/hooks/useNavigation";
import { isStreamingKit } from "../lib/utils";
import {Role} from '../lib/peerTypes';


export const LeaveRoom = () => {
	const navigate = useNavigation();
	// const params = useParams();
	const [showEndRoomModal, setShowEndRoomModal] = useState(false);
	const [lockRoom, setLockRoom] = useState(false);
	const isConnected = useHMSStore(selectIsConnectedToRoom);
	const permissions = useHMSStore(selectPermissions);
	const hmsActions = useHMSActions();
	const localPeerRole = useHMSStore(selectLocalPeerRoleName);


	const redirectToLeavePage = () => {
		// if (params.role) {
		//   navigate("/leave/" + params.roomId + "/" + params.role);
		// } else {
		//   navigate("/leave/" + params.roomId);
		// }
		navigate("/leave");
	};

	const leaveRoom = () => {
		hmsActions.leave();
		redirectToLeavePage();
	};

	const endRoom = () => {
		hmsActions.endRoom(lockRoom, "End Room");
		redirectToLeavePage();
	};

	const isStreamKit = isStreamingKit();
	if (!permissions || !isConnected) {
		return null;
	}

	return (
		<Fragment>
			{(permissions.endRoom && (localPeerRole === Role.Director || localPeerRole === Role.Commander)) ? (
				<Flex direction="row">
					<LeaveIconButton
						key="LeaveRoom"
						data-testid="leave_room_btn"
						onClick={leaveRoom}
						color="warning"
					>
						<Tooltip title="Veranstaltung verlassen" className="!normal-case">
							{!isStreamKit ? (
								<Box>
									<HangUpIcon key="hangUp" />
								</Box>
							) : (
								<Flex gap={2}>
									<Box style={{transform: "rotate(180deg)" }}>
										<ExitIcon key="hangUp" />
									</Box>
									<span className="btn-typo hide sm:inline">
										Verlassen
									</span>
								</Flex>
							)}
						</Tooltip>
					</LeaveIconButton>
					<Dropdown.Root>
						<Dropdown.Trigger className="data-dataOpen:bg-app-tertiary-800">
							<MenuTriggerButton
								variant="danger"
								data-testid="leave_end_dropdown_trigger"
								color="warning"
							>
								<VerticalMenuIcon />
							</MenuTriggerButton>
						</Dropdown.Trigger>
						<Dropdown.Content className="p-0" alignOffset={-50} sideOffset={10}>
							<Dropdown.Item
								className="w-full bg-[rgba(178,71,81,0.1)]"
								onClick={() => {
									setShowEndRoomModal(true);
								}}
								data-testid="end_room_btn"
							>
								<Flex direction="row" gap={4}>
									<Box className="text-app-tertiary-800">
										<AlertTriangleIcon />
									</Box>
									<Flex direction="column" align="start">
										<div className="text-lg text-app-tertiary-800">
											Für alle beenden
										</div>
										<div className="text-sm text-app-tertiary-800 mt-2">
											Achtung: Bitte die Konsequenzen beachten
										</div>
									</Flex>
								</Flex>
							</Dropdown.Item>
							<Dropdown.Item
								className="bg-app-surface"
								onClick={leaveRoom}
								data-testid="just_leave_btn"
							>
								<Flex direction="row" gap={4}>
									<Box>
										<ExitIcon />
									</Box>
									<Flex direction="column" align="start">
										<div className="text-lg">
											{isStreamKit ? "Studio verlassen" : "Veranstaltung verlassen"}
										</div>
										<div className="text-sm text-app-tertiary-800 mt-2">
											Wiedereinstieg möglich
										</div>
									</Flex>
								</Flex>
							</Dropdown.Item>
						</Dropdown.Content>
					</Dropdown.Root>
				</Flex>
			) : (
				<LeaveIconButton
					onClick={leaveRoom}
					key="LeaveRoom"
					data-testid="leave_room_btn"
					color="warning"
				>
					<Tooltip title="Veranstaltung verlassen"  className="!normal-case">
						<Box>
							{isStreamKit ? (
								<Box style={{transform: "rotate(180deg)" }}>
									<ExitIcon />
								</Box>
							) : (
								<HangUpIcon key="hangUp" />
							)}
						</Box>
					</Tooltip>
				</LeaveIconButton>
			)}

			<Dialog.Root
				open={showEndRoomModal}
				onOpenChange={value => {
					if (!value) {
						setLockRoom(false);
					}
					setShowEndRoomModal(value);
				}}
			>
				<DialogContent title="Veranstaltung beenden" Icon={HangUpIcon}>
					<DialogCheckbox
						id="lockRoom"
						title="Raum sperren"
						value={lockRoom}
						onChange={setLockRoom}
					/>
					<DialogRow justify="end">
						<Button
							color="warning"
							onClick={endRoom}
							data-testid="lock_end_room"
						>
							Raum sperren
						</Button>
					</DialogRow>
				</DialogContent>
			</Dialog.Root>
		</Fragment>
	);
};

const LeaveIconButton = (props) => <IconButton {...props} tw="text-app-text h-12 bg-app-tertiary-800 enabled:hover:bg-app-tertiary-600
"/>;

const MenuTriggerButton = (props) => <LeaveIconButton {...props} tw="border-1 bg-app-tertiary-800 border-app-tertiary-800 py-2"/>;


// const LeaveIconButton = styled(IconButton, {
//   color: "$white",
//   h: "$14",
//   px: "$8",
//   r: "$1",
//   bg: "$error",
//   "&:not([disabled]):hover": {
//     bg: "$errorTint",
//   },
//   "&:not([disabled]):active": {
//     bg: "$errorTint",
//   },
//   "@md": {
//     px: "$4",
//     mx: 0,
//   },
// });

// const MenuTriggerButton = styled(LeaveIconButton, {
//   borderLeft: "1px solid $errorDark",
//   borderTopLeftRadius: 0,
//   borderBottomLeftRadius: 0,
//   px: "$3",
//   "@md": {
//     px: "$2",
//   },
// });
