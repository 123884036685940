import React, { Suspense, useCallback, useEffect } from "react";
import {
	BrowserRouter as Router,
	Navigate,
	Route,
	Routes,
} from "react-router-dom";
import {
	HMSRoomProvider,
	selectIsConnectedToRoom,
	useHMSActions,
	useHMSStore,
} from "@100mslive/react-sdk";
import { HMSThemeProvider } from "@100mslive/react-ui";
// import { css } from '@emotion/css';
// import tw from 'twin.macro';
import clsx from "clsx";
import { AppData } from "./components/AppData/AppData";
import { ErrorBoundary } from "./components/ErrorBoundary";
import ErrorPage from "./views/ErrorPage";
import HomeScreen from "./views/HomeScreen";
import FullPageProgress from "./components/FullPageProgress";
import { Init } from "./components/init/Init";
import { KeyboardHandler } from "./components/Input/KeyboardInputManager";
import { Notifications } from "./components/Notifications";
import PostLeave from "./views/PostLeave";
import { ToastContainer } from "./components/Toast/ToastContainer";
import { hmsActions, hmsNotifications, hmsStats, hmsStore } from "./hms.js";
import { Confetti } from "./plugins/confetti";
import { RemoteStopScreenshare } from "./plugins/RemoteStopScreenshare";
import { getRoutePrefix, shadeColor } from "./lib/utils";
import { FeatureFlags } from "./services/FeatureFlags";
import {
	getBackendEndpoint,
	getUserToken as defaultGetUserToken,
} from "./services/tokenService";
// import CustomEventsListener from "./CustomEventsListener";
import "./styles/App.css";

const Conference = React.lazy(() => import("./views/Conference"));
const PreviewScreen = React.lazy(() => import("./views/PreviewScreen"));
const LoginPage = React.lazy(() => import("./views/LoginPage"));

const defaultTokenEndpoint = process.env
	.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
	? `${getBackendEndpoint()}${
			process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT_DOMAIN
		}/`
	: process.env.REACT_APP_TOKEN_GENERATION_ENDPOINT;

const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

export function MainComponent({
	tokenEndpoint = defaultTokenEndpoint,
	themeConfig: {
		aspectRatio = "1-1",
		// font = "theinhardt",
		// color = "#2F80FF",
		// theme = "dark",
		logo = "",
		headerPresent = "false",
		metadata = "",
		recordingUrl = "",
	},
	getUserToken = defaultGetUserToken,
	policyConfig = envPolicyConfig,
	getDetails = () => {},
}) {
	// const { 0: width, 1: height } = aspectRatio
	// 	.split("-")
	// 	.map(el => parseInt(el));

	const getUserTokenCallback = useCallback(getUserToken, []); //eslint-disable-line

	return (
		<ErrorBoundary>
			{/* <HMSThemeProvider
				themeType={theme}
				aspectRatio={{ width, height }}
				theme={{
					colors: {
						brandDefault: color,
						brandDark: shadeColor(color, -30),
						brandLight: shadeColor(color, 30),
						brandDisabled: shadeColor(color, 10),
					},
					fonts: {
						sans: [font, "Inter", "sans-serif"],
					},
				}}
			> */}
				<HMSRoomProvider
					isHMSStatsOn={FeatureFlags.enableStatsForNerds}
					actions={hmsActions}
					store={hmsStore}
					notifications={hmsNotifications}
					stats={hmsStats}
				>
					<AppData
						appDetails={metadata}
						policyConfig={policyConfig}
						recordingUrl={recordingUrl}
						logo={logo}
						tokenEndpoint={tokenEndpoint}
					/>

					<Init />
					<div className={clsx('w-full', headerPresent ? 'flex h-full' : 'h-full')}>
						<AppRoutes
							getUserToken={getUserTokenCallback}
							getDetails={getDetails}
						/>
					</div>
				</HMSRoomProvider>
			{/* </HMSThemeProvider> */}
		</ErrorBoundary>
	);
}

const RedirectToLogin = ({ getDetails }) => {
	// const { roomId, role } = useParams();
	
	// useEffect(() => {
	// 	getDetails();
	// }, [roomId]); //eslint-disable-line

	// console.error({ roomId, role });

	// if (!roomId && !role) {
	// 	return <Navigate to="/" />;
	// }
	// if (!roomId) {
	// 	return <Navigate to="/" />;
	// }
	// if (["streaming", "login", "preview", "event", "leave"].includes(roomId) && !role) {
	// 	return <Navigate to="/" />;
	// }

	return (
		// <Navigate to={`${getRoutePrefix()}/login/${roomId}/${role || ""}`} />
		<Navigate to={`${getRoutePrefix()}/login`} />
	);
};

const RouteList = ({ getUserToken, getDetails }) => {
	return (
		<Routes>
			<Route path="login"
				element={
					<LoginPage />
				}
			/>
			<Route path="preview"
				element={
					<Suspense fallback={<FullPageProgress />}>
						<PreviewScreen getUserToken={getUserToken} />
					</Suspense>
				}
			/>
			<Route
				path="event"
				element={
					<Suspense fallback={<FullPageProgress />}>
						<Conference />
					</Suspense>
				}
			/>
			<Route path="leave" element={<PostLeave />} />
			{/* <Route
				path="/:roomId/:role"
				element={<RedirectToLogin getDetails={getDetails} />}
			/>
			<Route
				path="/:roomId/"
				element={<RedirectToLogin getDetails={getDetails} />}
			/> */}
			<Route
				path="/"
				element={<HomeScreen />}
			/>
			<Route path="*" element={<ErrorPage error="Invalid URL!" />} />
		</Routes>
	);
};

const BackSwipe = () => {
	const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
	const hmsActions = useHMSActions();
	useEffect(() => {
		const onRouteLeave = async () => {
			if (isConnectedToRoom) {
				await hmsActions.leave();
			}
		};
		window.addEventListener("popstate", onRouteLeave);

		return () => {
			window.removeEventListener("popstate", onRouteLeave);
		};
	}, [hmsActions, isConnectedToRoom]);
	return null;
};


function AppRoutes({ getUserToken, getDetails }) {
	return (
		<Router>
			<ToastContainer />
			<Notifications />
			<BackSwipe />
			<Confetti />
			<RemoteStopScreenshare />
			<KeyboardHandler />
			<Routes>
				<Route
					path="/*"
					element={
						<RouteList getUserToken={getUserToken} getDetails={getDetails} />
					}
				/>
				<Route
					path="/streaming/*"
					element={
						<RouteList getUserToken={getUserToken} getDetails={getDetails} />
					}
				/>
			</Routes>
		</Router>
	);
}

export default function App() {
	return (
		<MainComponent
			themeConfig={{
				aspectRatio: process.env.REACT_APP_TILE_SHAPE,
				theme: process.env.REACT_APP_THEME,
				color: process.env.REACT_APP_COLOR,
				logo: process.env.REACT_APP_LOGO,
				font: process.env.REACT_APP_FONT,
				headerPresent: process.env.REACT_APP_HEADER_PRESENT,
				metadata: process.env.REACT_APP_DEFAULT_APP_DETAILS, // A stringified object in env
			}}
			getUserToken={defaultGetUserToken}
		/>
	);
}
