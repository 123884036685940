import React, { ComponentProps, PropsWithChildren, PropsWithRef } from 'react';
import {styled} from 'twin.macro'
// import { css } from '@emotion/react';
import tw, {theme, css} from 'twin.macro';
import { CopyIcon, EyeCloseIcon, EyeOpenIcon } from '../styles/AppIcons';

interface InputProps {
	hasError?: boolean;
}
type InputPropTypes = JSX.IntrinsicElements["input"] & InputProps;

export const Input = styled.input((props:InputPropTypes) => {
	return {
		fontFamily: theme`components.input.fontFamily`,
		backgroundColor: theme`colors.app.input.bg`,
		borderRadius: theme`components.input.borderRadius`,
		outline: 'none',
		border: props.hasError ? theme('components.input.borderError') : theme('components.input.border'),
		padding: `${theme('spacing[1.5]')} ${theme('spacing.3')}`,
		color: theme('colors.app.input.text'),
		fontSize: theme`components.input.fontSize`,
		'&:focus': {
			// boxShadow: `0 0 0 1px ${theme('colors.app.primary.300')}`,
			border: theme`components.input.borderFocus`,
		},
		'&::placeholder': {
			color: theme`colors.app.input.textDim`,
		},
	}});

const PasswordRoot = styled.div({
	width: '100%',
	position: 'relative',
	display: 'flex',
});

const PasswordShowIcon = ({
	showPassword,
	css,
	...props
}) => {
	return (
		<div css={[ {...css}, tw`flex` ]} {...props}>
			{showPassword ? <EyeOpenIcon /> : <EyeCloseIcon />}
		</div>
	);
};

const PasswordCopyIcon = ({ css, ...props }) => {
	return (
		<div css={[ {...css}, tw`flex` ]} {...props}>
			<CopyIcon />
		</div>
	);
};

const PasswordIcons = React.forwardRef<HTMLDivElement, PropsWithChildren<ComponentProps<any & { css?: typeof css }>>>(
	({ css, ...props }, ref) => {
		return (
			<div
				css={{
					display: 'flex',
					position: 'absolute',
					...tw`top-0 h-full rounded-sm`,
					zIndex: 10,
					bg: theme`colors.app.input.bg`,
					alignItems: 'center',
					...css,
				}}
				ref={ref}
				{...props}
			>
				{props.children}
			</div>
		);
	},
);

const ReactInput = React.forwardRef<HTMLInputElement,
		PropsWithRef<ComponentProps<typeof Input> & { showPassword?: boolean; css?: typeof css }>
	>(({ showPassword = false, css, ...props }, ref) => {
		return (
			<Input
				css={[{ flexGrow: 1, width: '100%'}, css ]}
				type={showPassword ? 'text' : 'password'}
				{...props}
				ref={ref}
			/>
		);
	});

export const PasswordInput = {
	Root: PasswordRoot,
	Icons: PasswordIcons,
	Input: ReactInput,
	ShowIcon: PasswordShowIcon,
	CopyIcon: PasswordCopyIcon,
};
