import React from "react";
// import tw, {theme, css} from 'twin.macro';
import {
	useHMSStore,
	selectLocalPeerRoleName,
	selectIsConnectedToRoom,
} from "@100mslive/react-sdk";
import { Flex, /*VerticalDivider*/ } from "../../mkui";
import {useHiliteDominantSpeaker } from "../AppData/useUISettings";
import { Logo, SpeakerTag } from "./HeaderComponents";
import { ParticipantCount } from "./ParticipantList";
import { StreamActions } from "./StreamActions";
import {LayoutSelector} from "./LayoutSelector";
import {Role} from '../../lib/peerTypes';


export const ConferencingHeader = ({ isPreview }) => {
	const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom)
	const localPeerRole = useHMSStore(selectLocalPeerRoleName) as Role;
	const hiliteDominantSpeaker = useHiliteDominantSpeaker();
	const showParticipantCount = [Role.Presenter, Role.Actor, Role.Videoplayer, Role.Audioplayer, Role.Moderator, Role.Director, Role.MK_Monitor].indexOf(localPeerRole) > -1
	const showLayoutSelector = [Role.Actor, Role.Moderator, Role.Director, Role.MK_Monitor].indexOf(localPeerRole) > -1
	const hideHeaderDuringEvent = isConnectedToRoom && ([Role.Audience, Role.Viewer, Role.Watchonly].indexOf(localPeerRole) > -1)

	if (hideHeaderDuringEvent) {
		return null
	}

	return (
		<Flex
			justify="between"
			align="center"
			direction="row"
			className="relative h-full mt-2"
		>
			<Flex align="center" direction="row" className="absolute left-5">
				{!isConnectedToRoom && <Logo />}
				{/* <VerticalDivider css={tw`ml-8`} /> */}
				{!isPreview && hiliteDominantSpeaker ? <SpeakerTag /> : null}
			</Flex>

			<Flex
				align="center"
				direction="row"
				className="absolute right-5"
				gap="4"
			>
				<StreamActions />
				{showLayoutSelector && <LayoutSelector />}
				{showParticipantCount && <ParticipantCount />}
			</Flex>
		</Flex>
	);
};
