import React from 'react';
import { HMSPeer, useVideo } from '@100mslive/react-sdk';
// import type { VariantProps } from '@stitches/react';
import tw, {styled} from 'twin.macro'
// import type {TwComponent} from 'twin.macro'
// import type { StyledComponent } from "@emotion/styled";

interface StyledVidProps {
	mirror?: boolean;
	screenShare?: boolean;
	degraded?: boolean;
}

// type StyledVidType = StyledComponent<"video", StyledVidProps, TwComponent<"video">>
// type StyledVidType = StyledComponent<"video"> & StyledVidProps
type StyledVidType = JSX.IntrinsicElements["video"] & StyledVidProps

//FIXME: implement correct type
//@ts-ignore
export const StyledVideo = styled.video(({mirror, screenShare, degraded}):StyledVidType => [
	tw`w-full h-full flex justify-center items-center rounded-sm`,
	mirror && tw`-scale-x-100`,
	screenShare ? tw`object-contain` : tw`object-cover`,
	degraded && tw`-z-100`
]);


interface Props {
	/**
	 * trackID for peer (videoTrack)
	 */
	trackId: HMSPeer['videoTrack'];
	/**
	 * Boolean stating whether to override the internal behaviour.
	 * when attach is false, even if tile is inView or enabled, it won't be rendered
	 */
	attach?: boolean;
	/**
	 * Number between 0 and 1 indication when the element is considered inView
	 */
	threshold?: number;
}

export const Video: React.FC<Props & StyledVidType> = ({ trackId, attach, threshold, ...props }) => {
const { videoRef } = useVideo({ trackId, attach /*, threshold*/ });
	return <StyledVideo autoPlay muted playsInline controls={false} ref={videoRef} {...props} />;
};

export default Video;
