/** @jsxImportSource @emotion/react */
import React from "react";
import tw, {theme, css} from 'twin.macro';
import { CheckIcon, AlertTriangleIcon } from "../../styles/AppIcons";
import {StyledDialog} from "../primitives/StyledDialog";
import {HorizontalDivider} from "../../mkui";
import {Label} from "../../mkui/Label";
import {
	Select,
	Switch,
	Checkbox,
} from "@100mslive/react-ui";
import { Button, Input } from "../../mkui";

export const Dialog = StyledDialog; // for the Dialog.Root element

const dialogBaseStyles = {
	background: theme`components.dialog.background`,
	color: theme`colors.app.dialog.text`,
	borderRadius: theme`components.dialog.borderRadius`,
	border: theme`components.dialog.border`,
	boxShadow: theme`components.dialog.boxShadow`,
};

export const DialogContent = ({
	Icon,
	title,
	closeable = true,
	children,
	extraStyles = {},
	iconCSS = {},
	...props
}) => {
	return (
		<StyledDialog.Portal>
			<StyledDialog.Overlay />
			<StyledDialog.Content css={{ width: "min(600px, 100%)", ...dialogBaseStyles, ...extraStyles }} {...props}>
				<StyledDialog.Title>
					<div className="flex justify-between">
						<div className="flex items-center mb-1">
							{Icon ? (
								<div css={[tw`mr-2 text-app-dialog-text self-start h-7 w-7`, css({...iconCSS})]}>
									<Icon className="w-full h-full"/>
								</div>
							) : null}
							<h4>
								{title}
							</h4>
						</div>
						{closeable && (
							<StyledDialog.DefaultClose data-testid="dialoge_cross_icon" />
						)}
					</div>
				</StyledDialog.Title>
				<HorizontalDivider css={tw`mt-3`} />
				<div>{children}</div>
			</StyledDialog.Content>
		</StyledDialog.Portal>
	);
};

export const ErrorDialog = ({
	open = true,
	onOpenChange,
	title,
	children,
	...props
}) => {
	return (
		<StyledDialog.Root open={open} onOpenChange={onOpenChange}>
			<DialogContent
				Icon={AlertTriangleIcon}
				title={title}
				onInteractOutside={e => e.preventDefault()}
				onEscapeKeyDown={e => e.preventDefault()}
				onPointerDownOutside={e => e.preventDefault()}
				closeable={true}
				iconCSS={{ color: theme`colors.app.dialog.text` }}
				{...props}
			>
				<div className="mt-6">{children}</div>
			</DialogContent>
		</StyledDialog.Root>
	);
};

export const RequestDialog = ({
	open = true,
	onOpenChange,
	title,
	body,
	actionText = "Ok",
	onAction,
	Icon,
}) => (
	<StyledDialog.Root open={open} onOpenChange={onOpenChange}>
		<StyledDialog.Portal>
			<StyledDialog.Overlay />
			<StyledDialog.Content css={[{width: 'min(400px, 80%'}, tw`p-12`, dialogBaseStyles]}>
				<StyledDialog.Title className="flex flex-row p-0 gap-6">
					{Icon ? Icon : null}
					<h2>{title}</h2>
				</StyledDialog.Title>
				<div className="text-base mt-4 mb-12 text-app-dialog-text">
					{body}
				</div>
				<div className="flex justify-center items-center w-full gap-6">
					<div className="w-1/2">
						<StyledDialog.Close className="w-full">
							<Button color="neutral" outline className="w-full">
								Cancel
							</Button>
						</StyledDialog.Close>
					</div>
					<div className="w-1/2">
						<Button
							color="primary"
							className="w-full"
							onClick={onAction}
						>
							{actionText}
						</Button>
					</div>
				</div>
			</StyledDialog.Content>
		</StyledDialog.Portal>
	</StyledDialog.Root>
);

/**
 * a row of items which breaks into column on small screen. For e.g. title on left and options to select
 * from on right for select component.
 */

interface IDialogRowProps {
	children: any;
	breakSm?: boolean;
	justify?: string;
	extraStyles?: string | null;
};

export const DialogRow = ({
	children,
	breakSm = false,
	extraStyles = null,
	justify = "between",
}:IDialogRowProps) => {
	
	return (
		<div css={[tw`flex mx-0 my-6 w-full`, css({justifySelf: justify}), breakSm && tw`sm:(flex-col items-start)`, extraStyles && css(extraStyles)]}>
			{children}
		</div>
	);
};

/**
 * key field and label field are optional, option is directly used if not passed
 */
export const DialogSelect = ({
	title,
	options,
	keyField,
	labelField,
	selected,
	onChange,
	...props
}) => {
	return (
		<DialogRow breakSm>
			<Label>{title}</Label>
			<Select.Root
				data-testid={`dialog_select_${title}`}
				css={{ width: "70%", "@sm": { width: "100%" } }}
				{...props}
			>
				<Select.DefaultDownIcon />
				<Select.Select
					onChange={e => onChange(e.target.value)}
					value={selected}
					css={{ width: "100%" }}
				>
					{options.map(option => {
						const id = keyField ? option[keyField] : option;
						const label = labelField ? option[labelField] : option;
						return (
							<option value={id} key={id}>
								{label}
							</option>
						);
					})}
				</Select.Select>
			</Select.Root>
		</DialogRow>
	);
};

export const DialogInput = ({
	title,
	value,
	onChange,
	placeholder,
	disabled,
	type,
	...props
}) => {
	return (
		<DialogRow breakSm>
			<Label>{title}</Label>
			<Input
				css={{ width: "70%", "@sm": { width: "100%" } }}
				value={value}
				onChange={e => onChange(e.target.value)}
				placeholder={placeholder}
				disabled={disabled}
				type={type}
				{...props}
			/>
		</DialogRow>
	);
};

export const DialogSwitch = ({ title, value, onChange, disabled }) => {
	return (
		<DialogRow>
			<Label>{title}</Label>
			<div className="flex justify-end w-[70%]">
				<Switch
					checked={value}
					disabled={disabled}
					onCheckedChange={onChange}
				/>
			</div>
		</DialogRow>
	);
};

interface IDialogCheckboxProps {
	title: string;
	value: any;
	onChange: (val:any) => void;
	disabled?: boolean;
	extraStyles?: string | null;
	id: string;
};

export const DialogCheckbox = ({
	title,
	value,
	onChange,
	disabled,
	extraStyles = null,
	id,
}:IDialogCheckboxProps) => {
	return (
		<DialogRow extraStyles={extraStyles}>
			<Label htmlFor={id} className="cursor-pointer">
				{title}
			</Label>
			<Checkbox.Root
				checked={value}
				onCheckedChange={value => onChange(value)}
				disabled={disabled}
				id={id}
			>
				<Checkbox.Indicator>
					<CheckIcon width={16} height={16} />
				</Checkbox.Indicator>
			</Checkbox.Root>
		</DialogRow>
	);
};
