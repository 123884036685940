import { useCallback } from "react";
import {
  selectAppData,
  selectAppDataByPath,
  selectTrackByID,
  useHMSActions,
  useHMSStore,
  useHMSVanillaStore,
} from "@100mslive/react-sdk";
import {
  UserPreferencesKeys,
  useUserPreferences,
} from "../hooks/useUserPreferences";
import { APP_DATA, UI_SETTINGS } from "../../lib/constants";


/**
 * fields saved related to UI settings in store's app data can be
 * accessed using this hook. key is optional if not passed
 * the whole UI settings object is returned. Usage -
 * 1. val = useUiSettings("isAudioOnly");
 *    console.log(val); // false
 * 2. val = useUISettings();
 *    console.log(val); // {isAudioOnly: false}
 * @param {string | undefined} uiSettingKey
 */
export const useUISettings = (uiSettingKey?:string) => {
  const uiSettings = useHMSStore(
    // @ts-ignore
    selectAppDataByPath(APP_DATA.uiSettings, uiSettingKey)
  );
  return uiSettings;
};

/**
 * fields saved related to UI settings in store's app data can be
 * accessed using this hook. key is optional if not passed
 * the whole UI settings object is returned. Usage -
 * [val, setVal] = useUiSettings("isAudioOnly");
 * console.log(val); // false
 * setVal(true);
 * @param {string | undefined} uiSettingKey
 */
export const useSetUISettings = (uiSettingKey?:string) => {
  const value = useUISettings(uiSettingKey);
  const setValue = useSetAppData({
    key1: APP_DATA.uiSettings,
    key2: uiSettingKey,
  });
  return [value, setValue] as const;
};

export const useIsHeadless = () => {
  const isHeadless = useUISettings(UI_SETTINGS.isHeadless);
  return isHeadless;
};

export const useShowToolbar = () => {
  const showToolbar = useUISettings(UI_SETTINGS.showToolbar);
  return showToolbar;
};

export const useDoUseChat = () => {
  const doUseChat = useUISettings(UI_SETTINGS.useChat);
  return doUseChat;
};

export const useHiliteDominantSpeaker = () => {
  const doHiliteDS = useUISettings(UI_SETTINGS.hiliteDominantSpeaker);
  return doHiliteDS;
};
export const useHidePeerNames = () => {
  const doHide = useUISettings(UI_SETTINGS.hidePeerNames);
  return doHide;
};

export const useHLSViewerRole = () => {
  return useHMSStore(selectAppData(APP_DATA.hlsViewerRole));
};

export const useWaitingViewerRole = () => {
  return useHMSStore(selectAppData(APP_DATA.waitingViewerRole));
};
export const useIsHLSStartedFromUI = () => {
  return useHMSStore(selectAppData(APP_DATA.hlsStarted));
};

export const useIsRTMPStartedFromUI = () => {
  return useHMSStore(selectAppData(APP_DATA.rtmpStarted));
};

export const useTokenEndpoint = () => {
  return useHMSStore(selectAppData(APP_DATA.tokenEndpoint));
};

export const useLogo = () => {
  return useHMSStore(selectAppData(APP_DATA.logo));
};

export const useUrlToEmbed = () => {
  return useHMSStore(selectAppData(APP_DATA.embedConfig))?.url;
};

export const usePinnedTrack = () => {
  const pinnedTrackId = useHMSStore(selectAppData(APP_DATA.pinnedTrackId));
  return useHMSStore(selectTrackByID(pinnedTrackId));
};

export const useSubscribedNotifications = (notificationKey?:string) => {
  const notificationPreference = useHMSStore(
    //@ts-ignore
    selectAppDataByPath(APP_DATA.subscribedNotifications, notificationKey)
  );
  return notificationPreference;
};

export const useSetSubscribedNotifications = (notificationKey?:string) => {
  const value = useSubscribedNotifications(notificationKey);
  const setValue = useSetAppData({
    key1: APP_DATA.subscribedNotifications,
    key2: notificationKey,
  });
  return [value, setValue];
};

export const useSubscribeChatSelector = chatSelectorKey => {
  const chatSelectorPreference = useHMSStore(
    selectAppDataByPath(APP_DATA.chatSelector, chatSelectorKey)
  );
  return chatSelectorPreference;
};

export const useSetSubscribedChatSelector = chatSelectorKey => {
  const value = useSubscribeChatSelector(chatSelectorKey);
  const setValue = useSetAppData({
    key1: APP_DATA.chatSelector,
    key2: chatSelectorKey,
  });
  return [value, setValue];
};

export const useSetAppDataByKey = (appDataKey:string)  => {
  const value = useHMSStore(selectAppData(appDataKey));
  const actions = useHMSActions();
  const setValue = useCallback(
    value => {
      actions.setAppData(appDataKey, value);
    },
    [actions, appDataKey]
  );
  return [value, setValue];
};

const useSetAppData = ({ key1, key2 }) => {
  const actions = useHMSActions();
  const store = useHMSVanillaStore();
  const [, setPreferences] = useUserPreferences(
    UserPreferencesKeys.UI_SETTINGS
  );
  const setValue = useCallback(
    value => {
      if (!key1) {
        return;
      }
      actions.setAppData(
        key1,
        key2
          ? {
              [key2]: value,
            }
          : value,
        true
      );
      const appData = store.getState(selectAppData());
      setPreferences({
        ...appData.uiSettings,
        subscribedNotifications: appData.subscribedNotifications,
      });
    },
    [actions, key1, key2, store, setPreferences]
  );
  return setValue;
};
