import React, { Component } from "react";
import LogRocket from "logrocket";
import { CopyIcon } from "@100mslive/react-icons";
import {
	Tooltip,
} from "@100mslive/react-ui";
import {withTranslation} from "react-i18next";
import ErrorWithSupportLink from "./ErrorWithSupportLink";

class ErrorBoundaryComp extends Component {
	constructor(props) {
		super(props);
		this.state = { error: null, errorInfo: null, isErrorCopied: false };
	}

	componentDidCatch(error, errorInfo) {
		console.error(`react error boundary - ${error.message}`, error, errorInfo);
		// Catch errors in any components below and re-render with error message
		this.setState(
			{
				error: error?.message,
				errorInfo: errorInfo,
			},
			() => {
				LogRocket.track("uiError", { error: this.state.error });
				LogRocket.captureMessage(`uiError: ${this.state.error}`, {
					tags: {
						error: this.state.error,
					},
					extra: this.state.errorInfo,
				});
			}
		);
	}

	render() {
		const { t } = this.props;
		if (this.state.errorInfo) {
			return (
				<div className="flex justify-center items-center w-full h-full text-app-text">
					<div className="relative overflow-auto">
						<img
							src="/img/error-bg-dark.svg"
							alt="Error background"
						/>
						<div className="flex flex-col absolute w-full h-full left-0 top-0 justify-center items-center">
							<div style={{ margin: "1.5rem" }}>
								<p>{`${t("Es ist ein Fehler aufgetreten.")} ERROR: ${this.state.error}`}</p>
								{ErrorWithSupportLink(`Please reload to see if it works.`)}
							</div>
							<div className="flex justify-center">
								<Tooltip title={t("Seite neu laden")}>
									<button
										className="app-btn-text"
										onClick={() => {
											window.location.reload();
										}}
										data-testid="join_again_btn"
									>
										{t("Neu laden")}
									</button>
								</Tooltip>
								<Tooltip title={t("Den Fehlertext kopieren")}>
									<button
										className="app-btn-text"
										onClick={() => {
											const { error, errorInfo } = this.state;
											navigator.clipboard.writeText(
												JSON.stringify({
													error,
													errorInfo,
												})
											);
											this.setState({ isErrorCopied: true });
										}}
										data-testid="join_again_btn"
									>
										<CopyIcon />{" "}
										{this.state.isErrorCopied ? t("Kopiert") : t("Fehler kopieren")}
									</button>
								</Tooltip>
							</div>

							<details style={{ whiteSpace: "pre-wrap", margin: "1.5rem" }}>
								<p>{this.state.error && this.state.error.toString()}</p>
								<br />
								<p>{JSON.stringify(this.state.errorInfo)}</p>
							</details>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export const ErrorBoundary = withTranslation()(ErrorBoundaryComp)
