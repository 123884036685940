import React, { forwardRef } from 'react';
import clsx from 'clsx';

type ButtonColors = "primary" | "secondary" | "tertiary" | "warning" | "neutral"
type ButtonSizes = "regular" | "large" | "small" | "xs"

const buttonClasses = {
	base: clsx('btn-text')
}

interface ButtonProps {
	size?: ButtonSizes;
	color?: ButtonColors;
	outline?: boolean;
	aslink?: boolean;
};

type PropTypes = JSX.IntrinsicElements["button"] & JSX.IntrinsicElements["a"] & ButtonProps;

export const Button = forwardRef<HTMLButtonElement & HTMLAnchorElement, PropTypes>(
	({
		children,
		size,
		color,
		outline,
		aslink = false,
		className,
		...rest
	}, ref) => {
		if (aslink) {
			return (
				<a
					ref={ref}
					className={
						clsx(
							buttonClasses.base,
							size && `${size}`,
							!color && 'neutral', // neutral color is the default
							color && `${color}`,
							outline && 'btnoutline',
							className
						)
					}
					{...rest}
				>
					{children}
				</a>
			)
		} else {
			return (
				<button
					ref={ref}
					type="button"
					className={
						clsx(
							buttonClasses.base,
							size && `${size}`,
							!color && 'neutral', // neutral color is the default
							color && `${color}`,
							outline && 'btnoutline',
							className
						)
					}
					{...rest}
				>
					{children}
				</button>
			)
		}
	}
);

Button.displayName = "Button";


// export const Button = ({variant ="primary", size="regular"}:ButtonProps) => <StyledButton variant={variant} size={size} />

/*export const Button = React.forwardRef<
	HTMLButtonElement,
	PropsWithChildren<{ loading?: boolean } & ComponentPropsWithRef<typeof StyledButton>>
>(({ children, loading = false, icon, ...props }, ref) => {
	return (
		<StyledButton {...props} ref={ref}>
			<>
				{loading && (
					<div className="absolute left-0 flex items-center justify-center w-full">
						<Loading color="currentColor" />
					</div>
				)}
				<div className="flex items-center justify-center"
					css={{ visibility: loading ? 'hidden' : 'visible', gap: icon ? theme`spacing.2` : '0' }}
				>
					{children}
				</div>
			</>
		</StyledButton>
	);
});*/
