import React from "react";
import { useHMSStore, selectIsConnectedToRoom, selectSessionMetadata } from '@100mslive/react-sdk';
// import { useEventLayout } from "../AppData/useEventLayout";
import {useSetSessionMetadata} from '..//hooks/useSetSessionMetadata'
import {SessionMetadataType} from '../hooks/useRefreshSessionMetadataStatus'
import {ViewLayout} from "../../lib/layoutDefs";
// import useCustomEventsListener, {CustomEventType} from "../../CustomEventsListener";

const layouts = [
	{label: "Ssp. dyn. Zentrum, Pub. o/u", value: ViewLayout.ActDScAsp__Aud1Rt_Aud2Rb},
	{label: "Ssp. dyn. Zentrum, Aspekt, ohne Pub.", value: ViewLayout.ActDScAsp__noAud},
	{label: "Ssp. dyn. Zentrum, anpassen, ohne Pub.", value: ViewLayout.ActDScFit__noAud},
	{label: "Ssp. dyn. rechts, anpassen, Pub. links", value: ViewLayout.ActDSrFit__Aud1Rl},
	{label: "Ssp. dyn. rechts, Aspekt, Pub. links", value: ViewLayout.ActDSrAsp__Aud1Rl},
	{label: "Ssp. 1 rechts, Aspekt, Pub. links", value: ViewLayout.Act1SrAsp__Aud1Rl},
	{label: "Ssp. 2 rechts, Aspekt, Pub. links", value: ViewLayout.Act2SrAsp__Aud1Rl},
	{label: "Ssp. 3 rechts, Aspekt, Pub. links", value: ViewLayout.Act3SrAsp__Aud1Rl},
	{label: "Video 1 rechts, Aspekt, Pub. links", value: ViewLayout.Vid1SrAsp__Aud1Rl},
	{label: "Schauspieler 1 (16/9)", value: ViewLayout.Act1ScAsp__noAud},
	{label: "Schauspieler 1 (anpassen)", value: ViewLayout.Act1ScFit__noAud},
	{label: "Video 1 (16/9)", value: ViewLayout.Vid1ScAsp__noAud},
	{label: "Video 1 (anpassen)", value: ViewLayout.Vid1ScFit__noAud},
]

export const LayoutSelector = () => {
	const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
	// const curLayout = useEventLayout();
	// const { sendCustomEvent } = useCustomEventsListener();
	const {setSessionMetadata} = useSetSessionMetadata()
	const curSessionMetadata = useHMSStore(selectSessionMetadata);
	const viewLayout = curSessionMetadata ? curSessionMetadata[SessionMetadataType.viewLayout] : ViewLayout.Act1Sc__Aud1Rt_Aud2Rb;

	// function handleChange(evt) { <-- old version with custom event
	// 	sendCustomEvent({
	// 		type: CustomEventType.ChangeLayout,
	// 		data: evt.target.value
	// 	})
	// }

	function handleChange(evt) {
		setSessionMetadata({
			mdType: SessionMetadataType.viewLayout,
			value: evt.target.value
		})
	}

	
	if (!isConnectedToRoom) {
		return null;
	}

	return (
		<select className="mt-1 mb-2 mx-2 block py-1 px-2 border border-gray-700 bg-gray-700 rounded-sm shadow-sm focus:outline-none focus:ring-gray-500 focus:border-gray-500 sm:text-sm" value={viewLayout} onChange={handleChange}>
			{layouts.map((el) => {
				return (
					<option key={el.value} value={el.value}>{el.label}</option>
				)
			})}
		</select>
	)
};
